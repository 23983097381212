<template>
  <div class="amend-pass bsbb">
    <van-nav-bar
      title="修改支付密码"
      left-arrow
      @click-left="onClickLeft"
      :border="false"

    />
    <van-form @submit="onSubmit" style="margin-top: 20px;" class="reist">

      <van-field v-model="password" :type="passwordShow ? 'text' : 'password'" name="password" label-width="4rem"
        placeholder="请设置您的支付密码(最少6位)" style="margin-bottom: 20px;" />

      <van-field v-model="smsCodeNum" center clearable placeholder="请输入短信验证码">
        <template #button>
          <van-button size="big" @click="getSmsCode" type="primary" class="smsbtn">
      获取
          </van-button>
        </template>
      </van-field>

      <van-button block type="primary" native-type="submit" style="margin-bottom: 20px;background-color: green;">
        完成
      </van-button>
    </van-form>
   <!-- <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="userInfo.oldPass"
          name="原密码"
          label="原密码"
          placeholder="原密码"
          label-width="200px"
          :rules="[{ required: true, message: '请填写原密码' }]"
        />
        <van-field
          v-model="userInfo.newPass"
          name="新密码"
          label="新密码"
          placeholder="新密码"
          label-width="200px"
          :rules="[{ required: true, message: '请填写新密码' }]"
        />
        <van-field
          v-model="userInfo.confirmNew"
          name="确认新密码"
          label="确认新密码"
          placeholder="确认新密码"
          label-width="200px"
          :rules="[{ required: true, message: '请填写确认新密码' }]"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="success" native-type="submit">
          确认修改
        </van-button>
      </div>
    </van-form> -->
  </div>
</template>

<script>
import { getUserInfo,updatePassword,setPayPwd,getCode } from "@/utils/api";
export default {
  data() {
    return {
          mobile: "",
        password: "",
        newPass: ""


    };
  },
  created() {

      this.getUser();

  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getSmsCode() {

      getCode({ mobile: this.mobile }).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: "短信发送成功！请注意查收。",
            icon: 'success',
            className: 'noticeWidtht',
          });
          this.smsCodeText = this.smsCodeTime + "s 后重新获取";
          this.smsCodeInterval = setInterval(() => {
            this.smsCodeTime -= 1;
            this.smsCodeText = this.smsCodeTime + "s 后重新获取";
            if (this.smsCodeTime <= 0) {
              this.smsCodeText = "发送验证码";
              clearInterval(this.smsCodeInterval);
              console.log(this.smsCodeInterval);
            }
          }, 1000);
        } else {
          this.$toast({
            message: res.message,
            icon: 'fail',
            className: 'noticeWidtht',
          });
        }
      });
    },
   getUser() {
     getUserInfo().then((res) => {
       if (res.code === 200) {
         this.mobile = res.data.user.mobile;

       }
     });
   },
    onSubmit() {

      if (this.password === "") {
        this.$toast({
          message: "请输入登录密码",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      if (this.password < 6) {
        this.$toast({
          message: "密码至少6位数",
          icon: 'fail',
          className: 'noticeWidth',
        });
        return;
      }
      // if (this.password !== this.payPassword) {
      //   this.$toast({
      //     message: "2次密码不同",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   });
      //   return;
      // }
      // if (!this.smsCodeNum || this.smsCodeNum.length !== 4) {
      //   this.$toast({
      //     message: "请输入验证码",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   })
      //   return
      // }
      // if (this.code === undefined) {
      //   this.$toast({
      //     message: "请输入邀请码",
      //     icon: 'fail',
      //     className: 'noticeWidth',
      //   });
      //   return;
      // }

      // this.loading = true;
      const param = {
        password: this.password,
        newPassword: this.password,
        mscode: this.smsCodeNum,
      };
      setPayPwd(param).then(res => {
          if (res.code === 200) {
            this.$dialog.alert({
              message: res.message,
              theme: 'round-button',
              confirmButtonColor: 'green'
            }).then(() => {
              this.onClickLeft()
            })
          } else {
            this.$toast(res.message)
          }
        })
      },

    },
  //   async confirm(param) {
  //     this.$toast.loading({
  //       duration: 0,
  //       message: "提交中...",
  //       forbidClick: true,
  //       className: 'noticeWidth',
  //     });
  //     await this.$store.dispatch("/user/updatePassword", param).then(() => {

  //       this.$toast.success({
  //         message: "注册成功",
  //         icon: 'success',
  //         className: 'noticeWidth',
  //         duration: 1500,
  //       });
  //     }).catch(() => {
  //       // this.loading = false;
  //       this.$toast({
  //         message: '注册失败',
  //         icon: 'fail',
  //         className: 'noticeWidths',
  //       });
  //     });
  //   },

  // },
};
</script>

<style lang="scss" scoped>
.amend-pass {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 40px 50px;
  .van-field {
    margin-top: 40px;
  }
  .van-button {
    margin-top: 40px;
    padding: 40px;
  }
}
.smsbtn{
  background-color: green;
  border-radius: 10px;
}
</style>
